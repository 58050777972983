@import '../../../styles/main.scss';

.liveHolder {
  display: flex;
  flex-direction: column;
  width: 98%;
  justify-content: space-between;
  margin: 0 auto;
  background: #fff;
  padding: 2%;

  .timerHolder {
    cursor: pointer;
  }

  .liveTable {
    width: 100%;

    th,
    td {
      border: 1px solid;
      padding: 5px;

      &.red {
        background: rgba(255, 0, 0, 0.5);
      }

      &.green {
        background: rgba(0, 255, 34, 0.5);
      }
    }
  }
}
