@import '../../styles/main.scss';

.headerHolder {
  display: flex;

  .homeHolder {
    // width: 10%;

    .homeButton {
      display: inline-block;
      padding: 10px;

      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  .menuItems {
    display: flex;
    align-items: center;

    .menuButton {
      padding: 15px;
      color: #fff;
    }
  }
}
