@import '../../styles/main.scss';

.leaderboardHolder {
  min-height: 90vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .comingSoonModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 50%;
    margin: 0 auto 40px;
    background: $WHITE !important;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #c3c3c3;

    @media (max-width: $MOBILE) {
      width: 70%;
    }
  }
}
