@import '../../styles/main.scss';

.link {
  padding: 5px 40px;
  color: $BUTTON_TEXT;
  background-color: $BUTTON_BG;
  letter-spacing: 1px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  outline: none;
  font-weight: 600;
  display: inline-block;

  &:hover {
    color: $BUTTON_TEXT;
    text-decoration: none;
  }

  &.reverse {
    background-color: $BUTTON_TEXT;
    color: $BUTTON_BG;
  }
}
