@import '../../../styles/main.scss';

.playerTable {
  width: 90%;
  margin: 0 auto;

  th,
  td {
    border: 1px solid;
    padding: 5px;

    &.red {
      background: rgba(255, 0, 0, 0.5);
    }

    &.green {
      background: rgba(0, 255, 34, 0.5);
    }
  }
}
