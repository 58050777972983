@import './styles/main.scss';

html,
body {
  margin: 0;
}

#GolfPoolWebapp {
  min-height: 100vh;
  background: rgb(237, 0, 48);
  background: -moz-radial-gradient(
    circle,
    rgba(237, 0, 48, 1) 0%,
    rgba(252, 244, 0, 1) 10%,
    rgba(7, 102, 82, 1) 59%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(237, 0, 48, 1) 0%,
    rgba(252, 244, 0, 1) 10%,
    rgba(7, 102, 82, 1) 59%
  );
  background: radial-gradient(
    circle,
    rgba(237, 0, 48, 1) 0%,
    rgba(252, 244, 0, 1) 10%,
    rgba(7, 102, 82, 1) 59%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ed0030",endColorstr="#076652",GradientType=1);
}

.select-search-input,
.select-search-select,
.select-search-option,
.select-search-not-found {
  background: #fff !important;
  color: #000 !important;
}
