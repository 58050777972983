@import '../../styles/main.scss';

.liveHolder {
  display: flex;
  flex-direction: column;
  width: 98%;
  justify-content: space-between;
  margin: 0 auto;
  background: #fff;
  padding: 2%;
  overflow-x: scroll;

  .timerHolder {
    cursor: pointer;
  }

  .liveTable {
    width: 100%;

    th,
    td {
      border: 1px solid;
      padding: 5px;
      cursor: pointer;
    }
  }
}
