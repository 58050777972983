@import '../../styles/main.scss';

.btn {
  margin: 10px auto;
  padding: 10px 40px;
  color: $BUTTON_TEXT;
  background-color: $BUTTON_BG;
  letter-spacing: 1px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  outline: none;
  font-weight: 600;
  display: inline-block;

  &.reverse {
    background-color: $BUTTON_TEXT;
    color: $BUTTON_BG;
  }
}
